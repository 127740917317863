import Enum from './Enum';

class EntityStatus extends Enum {
  INVALID = 0;

  VALID = 1;

  labels() {
    const labels = {};
    labels[this.INVALID] = 'Inactive';
    labels[this.VALID] = 'Active';
    return labels;
  }
}

export default new EntityStatus();
