/* eslint-disable lines-between-class-members */
import Enum from './Enum';

// OnlineOrder 中用 ShippingMethod
class OrderSourceType extends Enum {
  OrderSourceTypeOne = 1;
  OrderSourceTypeTwo = 2;
  ONLINE = 3;
  OrderSourceTypeFOUR = 4;
  QRSCAN = 5;
}

export default new OrderSourceType();
