import axios from 'axios';

const getStockInfo = (date) => {
  return axios.get(`/mole/api/special/stock/${date}`, {
    auth: {
      username: 'backoffice',
      password: 'Mole@ZiiForce312$',
    },
  });
};

export default {
  getStockInfo,
};
