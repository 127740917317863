import {
  orderSourceKey,
  orderTypeKey,
  mealTypeKey,
  shippingMethodKey,
  tableNumKey,
  orderCartKey,
  orderMoreCartKey,
  orderDetailKey,
  orderRelatedBuzIdKey,
  orderBillTypeKey,
  orderPreferenceKey,
} from '@/configs/main';
import { OrderSourceType, OrderType, ShippingMethod } from '@/enums';

export const getOrderSource = () => {
  let data = OrderSourceType.ONLINE;
  try {
    if (sessionStorage.getItem(orderSourceKey)) {
      data = parseInt(sessionStorage.getItem(orderSourceKey), 10);
    }
  } catch (error) {
    console.log('>>> src/helpers/Utils.js -> getOrderSource error:', error);
  }
  return data;
};

export const setOrderSource = (payload) => {
  try {
    sessionStorage.setItem(orderSourceKey, payload);
  } catch (error) {
    console.log('>>> src/helpers/Utils.js -> setOrderSource error:', error);
  }
};

export const getOrderType = () => {
  let data = OrderType.Retail;
  try {
    if (sessionStorage.getItem(orderTypeKey)) {
      data = parseInt(sessionStorage.getItem(orderTypeKey), 10);
    }
  } catch (error) {
    console.log('>>> src/helpers/Utils.js -> getOrderType error:', error);
  }
  return data;
};

export const setOrderType = (payload) => {
  try {
    sessionStorage.setItem(orderTypeKey, payload);
  } catch (error) {
    console.log('>>> src/helpers/Utils.js -> setOrderType error:', error);
  }
};

export const getMealType = () => {
  let data = null;
  try {
    if (sessionStorage.getItem(mealTypeKey)) {
      data = parseInt(sessionStorage.getItem(mealTypeKey), 10);
    }
  } catch (error) {
    console.log('>>> src/helpers/Utils.js -> getMealType error:', error);
  }
  return data;
};

export const setMealType = (payload) => {
  try {
    if (payload) {
      sessionStorage.setItem(mealTypeKey, payload);
    } else {
      sessionStorage.removeItem(mealTypeKey);
    }
  } catch (error) {
    console.log('>>> src/helpers/Utils.js -> setMealType error:', error);
  }
};

export const getShippingMethod = () => {
  let data = ShippingMethod.PICKUP;
  try {
    if (sessionStorage.getItem(shippingMethodKey)) {
      data = parseInt(sessionStorage.getItem(shippingMethodKey), 10);
    }
  } catch (error) {
    console.log('>>> src/helpers/Utils.js -> getShippingMethod error:', error);
  }
  return data;
};

export const setShippingMethod = (payload) => {
  try {
    if (payload) {
      sessionStorage.setItem(shippingMethodKey, payload);
    } else {
      sessionStorage.removeItem(shippingMethodKey);
    }
  } catch (error) {
    console.log('>>> src/helpers/Utils.js -> setShippingMethod error:', error);
  }
};

export const getTableNum = () => {
  let data = null;
  try {
    data = sessionStorage.getItem(tableNumKey);
  } catch (error) {
    console.log('>>> src/helpers/Utils.js -> getTableNum error:', error);
  }
  return data;
};

export const setTableNum = (payload) => {
  try {
    if (payload) {
      sessionStorage.setItem(tableNumKey, payload);
    } else {
      sessionStorage.removeItem(tableNumKey);
    }
  } catch (error) {
    console.log('>>> src/helpers/Utils.js -> setTableNum error:', error);
  }
};

export const getOrderCart = (mid, bid, s) => {
  let data = null;
  try {
    const key = `${orderCartKey}_${mid}_${bid}_${s}`;
    if (sessionStorage.getItem(key)) {
      data = JSON.parse(sessionStorage.getItem(key));
    }
  } catch (error) {
    console.log('>>> src/helpers/Utils.js -> getOrderCart error:', error);
  }
  return data;
};

export const setOrderCart = (mid, bid, s, cart) => {
  try {
    const key = `${orderCartKey}_${mid}_${bid}_${s}`;
    if (cart) {
      sessionStorage.setItem(key, JSON.stringify({ ...cart, s, mid, bid }));
    } else {
      sessionStorage.removeItem(key);
    }
  } catch (error) {
    console.log('>>> src/helpers/Utils.js -> setOrderCart error:', error);
  }
};


export const getOrderCartSpecial = (mid, bid, s) => {
  let data = null;
  try {
    const key = `${orderCartKey}_${mid}_${bid}_${s}_s`;
    if (sessionStorage.getItem(key)) {
      data = JSON.parse(sessionStorage.getItem(key));
    }
  } catch (error) {
    console.log('>>> src/helpers/Utils.js -> getOrderCart error:', error);
  }
  return data;
};

export const setOrderCartSpecial = (mid, bid, s, cart) => {
  try {
    const key = `${orderCartKey}_${mid}_${bid}_${s}_s`;
    if (cart) {
      sessionStorage.setItem(key, JSON.stringify({ ...cart, s, mid, bid }));
    } else {
      sessionStorage.removeItem(key);
    }
  } catch (error) {
    console.log('>>> src/helpers/Utils.js -> setOrderCart error:', error);
  }
};

export const getOrderMoreCart = (mid, bid, s) => {
  let data = null;
  try {
    const key = `${orderMoreCartKey}_${mid}_${bid}_${s}`;
    if (sessionStorage.getItem(key)) {
      data = JSON.parse(sessionStorage.getItem(key));
    }
  } catch (error) {
    console.log('>>> src/helpers/Utils.js -> getOrderMoreCart error:', error);
  }
  return data;
};

export const setOrderMoreCart = (mid, bid, s, cart) => {
  try {
    const key = `${orderMoreCartKey}_${mid}_${bid}_${s}`;
    if (cart) {
      sessionStorage.setItem(key, JSON.stringify({ ...cart, s, mid, bid }));
    } else {
      sessionStorage.removeItem(key);
    }
  } catch (error) {
    console.log('>>> src/helpers/Utils.js -> setOrderMoreCart error:', error);
  }
};

export const getOrderDetail = () => {
  let data = null;
  try {
    if (sessionStorage.getItem(orderDetailKey)) {
      data = JSON.parse(sessionStorage.getItem(orderDetailKey));
    }
  } catch (error) {
    console.log('>>> src/helpers/Utils.js -> getOrderDetailCart error:', error);
  }
  return data;
};

export const setOrderDetail = (payload) => {
  try {
    if (payload) {
      sessionStorage.setItem(orderDetailKey, JSON.stringify(payload));
    } else {
      sessionStorage.removeItem(orderDetailKey);
    }
  } catch (error) {
    console.log('>>> src/helpers/Utils.js -> setOrderDetailCart error:', error);
  }
};

export const getOrderRelatedBuzId = () => {
  let data = null;
  try {
    data = sessionStorage.getItem(orderRelatedBuzIdKey);
  } catch (error) {
    console.log(
      '>>> src/helpers/Utils.js -> getOrderRelatedBuzId error:',
      error
    );
  }
  return data;
};

export const setOrderRelatedBuzId = (payload) => {
  try {
    if (payload) {
      sessionStorage.setItem(orderRelatedBuzIdKey, payload);
    } else {
      sessionStorage.removeItem(orderRelatedBuzIdKey);
    }
  } catch (error) {
    console.log(
      '>>> src/helpers/Utils.js -> setOrderRelatedBuzId error:',
      error
    );
  }
};

export const getOrderBillType = () => {
  let data = 1;
  try {
    if (sessionStorage.getItem(orderBillTypeKey)) {
      data = parseInt(sessionStorage.getItem(orderBillTypeKey), 10);
    }
  } catch (error) {
    console.log('>>> src/helpers/Utils.js -> getOrderBillType error:', error);
  }
  return data;
};

export const setOrderBillType = (payload) => {
  try {
    if (payload) {
      sessionStorage.setItem(orderBillTypeKey, payload);
    } else {
      sessionStorage.removeItem(orderBillTypeKey);
    }
  } catch (error) {
    console.log('>>> src/helpers/Utils.js -> setOrderBillType error:', error);
  }
};

export const getOrderPreference = () => {
  let data = {};
  try {
    if (sessionStorage.getItem(orderPreferenceKey)) {
      data = JSON.parse(sessionStorage.getItem(orderPreferenceKey));
    }
  } catch (error) {
    console.log('>>> src/helpers/Utils.js -> getOrderPreference error:', error);
  }
  return data;
};

export const setOrderPreference = (payload) => {
  try {
    if (payload) {
      sessionStorage.setItem(orderPreferenceKey, JSON.stringify(payload));
    } else {
      sessionStorage.removeItem(orderPreferenceKey);
    }
  } catch (error) {
    console.log('>>> src/helpers/Utils.js -> setOrderPreference error:', error);
  }
};
