const email = (value) => {
  const regexp = /^[0-9a-zA-Z_.-]+[@][0-9a-zA-Z_.-]+([.][a-zA-Z]+){1,2}$/;
  return regexp.test(value);
};

const phone = (value) => {
  const regexp = /^(\d{9}|0\d{9})$/;
  return regexp.test(value);
};

const postal = (value) => {
  const regexp = /^\d{4}$/;
  // eslint-disable-next-line radix
  return regexp.test(value) && parseInt(value) > 1000;
};

const name = (value) => {
  const regexp = /^[a-zA-Z0-9\s.,]{3,32}$/;
  return regexp.test(value);
};

const password = (value) => {
  const regexp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d!@#$%^&*()-_]{8,32}$/;
  return regexp.test(value);
};

const paymentPassword = (value) => {
  const regexp = /^(\d{6})$/;
  return regexp.test(value);
};

const verifyCode = (value) => {
  const regexp = /^(\d{6})$/;
  return regexp.test(value);
};

const url = (value) => {
  const regexp = /(http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-/]))?/;
  return regexp.test(value);
};

const inTimeRange = (start, end) => {
  const now = new Date();
  if (now.getTime() >= start && now.getTime() < end) {
    return true;
  }
  return false;
};

export default {
  email,
  phone,
  name,
  postal,
  password,
  paymentPassword,
  verifyCode,
  url,
  inTimeRange,
};
