import Enum from './Enum';

class AlipayStatus extends Enum {
  SUCCESS = '9000';
  PENDING = '8000';
  FAIL = '4000';
  DUPLICATED = '5000';
  CANCELED = '6001';
  NETWORK_ISSUE = '6002';
  RESULT_UNKNOWN = '6004';

  labels() {
    const labels = {};
    labels[this.SUCCESS] = 'Completed';
    labels[this.PENDING] = 'Pending';
    labels[this.FAIL] = 'Failed';
    labels[this.DUPLICATED] = 'Duplicated';
    labels[this.CANCELED] = 'Canceled';
    labels[this.NETWORK_ISSUE] = 'Network Issue';
    labels[this.RESULT_UNKNOWN] = 'Unknown Result';
    return labels;
  }
}

export default new AlipayStatus();
