import i18n from 'i18next';
import Enum from './Enum';

class MealType extends Enum {
  DINEIN = 1;

  TAKEAWAY = 2;

  PICKUP = 3;

  DELIVERY = 4;

  labels() {
    const labels = {};
    labels[this.PICKUP] = i18n.t('order.type.pickUp');
    labels[this.DELIVERY] = i18n.t('order.type.delivery');
    labels[this.DINEIN] = i18n.t('order.type.dineIn');
    labels[this.TAKEAWAY] = i18n.t('order.type.takeAway');
    return labels;
  }
}

export default new MealType();
