import Client from './HttpClient';

const checkin = (merchantId, branchId, userName, phone, numberOfPeople) => {
  return Client.post(`/eagle-api/manager/${merchantId}/checkInActivitys`, {
    branchId,
    userName,
    phone,
    numberOfPeople,
  });
};

export default {
  checkin,
};
