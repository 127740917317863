// i18n configs
export const defaultLocale = 'en-AU';

// Theme configs
export const isMultiColorActive = false;
export const defaultColor = 'light.default';
export const isDarkSwitchActive = true;
export const defaultDirection = 'ltr';
export const colors = [
  'default',
  'bluenavy',
  'blueyale',
  'blueolympic',
  'greenmoss',
  'greenlime',
  'purplemonster',
  'redruby',
  'yellowgranola',
  'greysteel',
];
export const themeColorStorageKey = '__theme_selected_color';

// Authorization configs
export const isAuthGuardActive = true;
export const currentUser = {};

// Store configs
export const merchantIdKey = '__merchant_id';
export const branchIdKey = '__branch_id';
export const storeKey = '__store';

// Online Ordering configs
export const orderCartKey = '__order_cart'; // 普通购物车
export const orderMoreCartKey = '__order_more_cart'; // 加菜购物车
export const orderTypeKey = '__order_type';
export const shippingMethodKey = '__shipping_method';
export const mealTypeKey = '__meal_type';
export const orderSourceKey = '__order_source';
export const tableNumKey = '__table_num';
export const orderDetailKey = '__order_detail';
export const orderRelatedBuzIdKey = '__order_related_buz_id';
export const orderBillTypeKey = '__order_bill_type';
export const orderPreferenceKey = '__order_preference';
export const rootOrderKey = '__root_order'; // 主订单
export const categoryMenuKey = '__category_menu'; // 菜单
export const cartNodeKey = '__cart_node'; // 购物车
export const orderPersonsKey = '__order_persons'; // 就餐人数

export * from './defaultValues';
