import { combineReducers } from 'redux';
import auth from './auth/reducer';
import settings from './settings/reducer';
import onlineOrder from './online-order/reducer';

const reducers = combineReducers({
  auth,
  settings,
  onlineOrder,
});

export default reducers;
