import Client from './HttpClient';

const list = () => {
  const url = '/eagle-api/delivery/address';
  return Client.get(url);
};

const find = (addressId) => {
  const url = `/eagle-api/delivery/address/${addressId}`;
  return Client.get(url);
};

const create = (
  consignee,
  mobile,
  postalCode,
  province,
  city,
  address,
  lng,
  lat
) => {
  const url = '/eagle-api/delivery/address';
  return Client.post(url, {
    consignee,
    mobile,
    postalCode,
    province,
    city,
    address,
    lng,
    lat,
  });
};

const update = (
  addressId,
  consignee,
  mobile,
  postalCode,
  province,
  city,
  address,
  lng,
  lat
) => {
  const url = `/eagle-api/delivery/address/${addressId}`;
  return Client.post(url, {
    consignee,
    mobile,
    postalCode,
    province,
    city,
    address,
    lng,
    lat,
  });
};

export default {
  list,
  find,
  create,
  update,
};
