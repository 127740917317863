import Client from './HttpClient';

const list = (merchantId, branchId) => {
  const url = `/eagle-api/online/product/${merchantId}/${branchId}/products`;
  return Client.get(url);
};

const listByCategory = (merchantId, branchId, CategoryId) => {
  const url = `/eagle-api/online/product/${merchantId}/${branchId}/products/${CategoryId}`;
  return Client.get(url);
};

const searchProducts = (merchantId, branchId, productName) => {
  const url = `/eagle-api/online/product/${merchantId}/${branchId}/products`;
  return Client.post(url, { productName });
};

const category = (merchantId, branchId) => {
  const url = `/eagle-api/online/product/${merchantId}/${branchId}/categorys`;
  return Client.get(url);
};

export default {
  list,
  category,
  listByCategory,
  searchProducts,
};
