/* eslint-disable no-new */
/* eslint-disable new-cap */
/* eslint-disable global-require */
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import vconsole from 'vconsole';
import { BrowserRouter, Route } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import { configureStore } from './redux/store';
import 'bootstrap-scss/bootstrap.scss';
import './i18n';
import {
  isMultiColorActive,
  defaultColor,
  isDarkSwitchActive,
  env,
} from './configs/main';
import { setCurrentColor, getCurrentColor, isMobile } from './helpers/Utils';

const App = React.lazy(() => import(/* webpackChunkName: "app" */ './App'));

if (isMobile() && env !== 'production') {
  new vconsole();
}

const color =
  isMultiColorActive || isDarkSwitchActive ? getCurrentColor() : defaultColor;
setCurrentColor(color);

import(`./styles/themes/${color}.scss`).then(() => {
  ReactDOM.render(
    <Provider store={configureStore()}>
      <Suspense fallback={<div className="loading" />}>
        <BrowserRouter>
          <Route path="/" component={App} />
        </BrowserRouter>
      </Suspense>
    </Provider>,
    document.getElementById('root')
  );
  /*
   * If you want your app to work offline and load faster, you can change
   * unregister() to register() below. Note this comes with some pitfalls.
   * Learn more about service workers: https://bit.ly/CRA-PWA
   */
  serviceWorker.unregister();
});
