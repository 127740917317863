import Client from './HttpClient';

const list = (merchantId = null, branchId = null, page = 1, pageSize = 20) => {
  return Client.get('/eagle-api/online/order/list', {
    merchantId,
    branchId,
    page,
    pageSize,
  });
};

const find = (orderId) => {
  return Client.get('/eagle-api/online/order/detail', { orderId });
};

const prePlace = (
  merchantId,
  branchId,
  orderItemList = [],
  useCouponId = null,
  usePointEqAmount = 0,
  useBalance = 0
) => {
  return Client.post('/eagle-api/online/order/try-calculation', {
    merchantId,
    branchId,
    orderItemList,
    useCouponId,
    usePointEqAmount,
    useBalance,
  });
};

const place = (
  merchantId,
  branchId,
  relatedBuzNo,
  deliveryMethod,
  paymentMethod,
  orderItemList,
  orderDate = null,
  deliveryTimeId = null,
  addressId = null,
  note = '',
  useCouponId = null,
  usePointEqAmount = 0,
  useBalance = 0
) => {
  return Client.post('/eagle-api/online/order/place', {
    merchantId,
    branchId,
    relatedBuzNo,
    deliveryMethod,
    paymentMethod,
    orderItemList,
    orderDate,
    deliveryTimeId,
    addressId,
    note,
    useCouponId,
    usePointEqAmount,
    useBalance,
  });
};

const paid = (ordeNo, paymentChannel, paymentApplyId) => {
  return Client.post('/eagle-api/online/order/pay-state', {
    ordeNo,
    payState: 1,
    paymentChannel,
    paymentApplyId,
  });
};

const payFailed = (ordeNo, paymentChannel, paymentApplyId) => {
  return Client.post('/eagle-api/online/order/pay-state', {
    ordeNo,
    payState: 2,
    paymentChannel,
    paymentApplyId,
  });
};

const getProfile = (merchantId, branchId) => {
  return Client.get('/eagle-api/common/aggregation/payment-delivery', {
    merchantId,
    branchId,
  });
};

const getProfileWithoutAuth = (merchantId, branchId) => {
  return Client.get('/eagle-api/common/aggregation/common-profile', {
    merchantId,
    branchId,
  });
};

const confirmReceived = (oderSn, merchantId, branchId, receivingCode) => {
  return Client.post(`/eagle-api/online/order/${oderSn}/receive`, {
    merchantId,
    branchId,
    receivingCode,
  });
};

const sendInvoice = (orderId, email) => {
  return Client.post(`/eagle-api/dishes/order/send/invoice/${orderId}`, {
    email,
  });
};

export default {
  list,
  find,
  prePlace,
  place,
  paid,
  payFailed,
  getProfile,
  getProfileWithoutAuth,
  confirmReceived,
  sendInvoice,
};
