import { getCurrencySymbol } from './WorkspaceHelper';
/* eslint-disable prefer-template */
const distance = (value) => {
  if (value === null || value === undefined) {
    return '--';
  }
  const d = parseFloat(value);
  const k = parseFloat(value) / 1000;
  if (k > 1) {
    return k.toFixed(1) + 'km';
  }
  return d.toFixed(0) + 'm';
};

const percent = (value, precision = 2) => {
  return (parseFloat(value) * 100).toFixed(precision) + '%';
};

const decimal = (value, precision = 2, dd = false) => {
  let p = precision;
  let v = parseFloat(value);
  if (Number.isNaN(v)) {
    v = 0;
  }
  if (dd) {
    const str = v.toFixed(precision).toString();
    for (let i = 1; i <= precision; i += 1) {
      if (str.substr(str.length - i, 1) === '0') {
        p -= 1;
      }
    }
  }
  return v.toFixed(p).replace(/\d+/, (n) => {
    return n.replace(/(\d)(?=(\d{3})+$)/g, ($1) => {
      return $1 + ',';
    });
  });
};

const currency = (value, precision = 2, dd = false) => {
  return getCurrencySymbol() + decimal(value, precision, dd);
};

const datetime = (timestamp, format = 'DD/MM/YYYY HH:mm:ss') => {
  const time = new Date(timestamp);
  return format
    .replace('YYYY', time.getFullYear().toString())
    .replace('MM', (time.getMonth() + 1).toString().padStart(2, '0'))
    .replace('DD', time.getDate().toString().padStart(2, '0'))
    .replace('HH', time.getHours().toString().padStart(2, '0'))
    .replace('mm', time.getMinutes().toString().padStart(2, '0'))
    .replace('ss', time.getSeconds().toString().padStart(2, '0'));
};

const date = (timestamp, format = 'MM/DD/YYYY') => {
  return datetime(timestamp, format);
};

const boolean = (value) => {
  return value ? 'YES' : 'NO';
};

export default {
  distance,
  percent,
  decimal,
  currency,
  datetime,
  date,
  boolean,
};
