/* eslint-disable lines-between-class-members */
import i18next from 'i18next';
import Enum from './Enum';

class ReservationDepositStatus extends Enum {
  UNPAID = 1;
  PENDING = 2;
  PAID = 3;
  REFUNDING = 4;
  REFUNDED = 5;
  FAIL = 6;
  REFUND_FAIL = 7;
  PARTIAL_REFUND = 8;

  labels() {
    const labels = {};
    labels[this.UNPAID] = i18next.t('reservation.depositStatus.unpaid');
    labels[this.PENDING] = i18next.t('reservation.depositStatus.pending');
    labels[this.PAID] = i18next.t('reservation.depositStatus.paid');
    labels[this.REFUNDING] = i18next.t('reservation.depositStatus.refunding');
    labels[this.REFUNDED] = i18next.t('reservation.depositStatus.refunded');
    labels[this.FAIL] = i18next.t('reservation.depositStatus.fail');
    labels[this.REFUND_FAIL] = i18next.t(
      'reservation.depositStatus.refundFail'
    );
    labels[this.PARTIAL_REFUND] = i18next.t(
      'reservation.depositStatus.partialRefunded'
    );
    return labels;
  }
}

export default new ReservationDepositStatus();
