import {
  getOrderSource,
  getOrderType,
  getMealType,
  getShippingMethod,
  getTableNum,
  getOrderCart,
  getOrderMoreCart,
  getOrderDetail,
  getOrderRelatedBuzId,
  getOrderBillType,
  getOrderPreference,
  getOrderCartSpecial,
} from '@/helpers/OrderUtil';
import { getCurrentBranchId, getCurrentMerchantId } from '@/helpers/Utils';
import {
  CHANGE_ORDER_TYPE,
  CHANGE_ORDER_SOURCE,
  CHANGE_MEAL_TYPE,
  CHANGE_SHIPPING_METHOD,
  CHANGE_TABLE_NUM,
  CHANGE_ORDER_CART,
  CHANGE_ORDER_MORE_CART,
  CHANGE_ORDER_DETAIL,
  CHANGE_ORDER_RELATED_BUZ_ID,
  CHANGE_ORDER_BILL_TYPE,
  CHANGE_ORDER_PREFERENCES,
  CHANGE_ORDER_CART_SPECIAL,
} from '../actions';

const mid = getCurrentMerchantId();
const bid = getCurrentBranchId();
const source = getOrderSource();

const INIT_STATE = {
  source: getOrderSource(),
  type: getOrderType(),
  mealType: getMealType(),
  shippingMethod: getShippingMethod(),
  tableNum: getTableNum(),
  orderCart: getOrderCart(mid, bid, source),
  orderCartSpecial: getOrderCartSpecial(mid, bid, source),
  orderMoreCart: getOrderMoreCart(mid, bid, source),
  orderDetail: getOrderDetail(),
  relatedBuzId: getOrderRelatedBuzId(),
  billType: getOrderBillType(),
  preference: getOrderPreference(),
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case CHANGE_ORDER_SOURCE:
      return { ...state, source: parseInt(action.payload, 10) };
    case CHANGE_ORDER_TYPE:
      return { ...state, type: parseInt(action.payload, 10) };
    case CHANGE_MEAL_TYPE:
      return { ...state, mealType: parseInt(action.payload, 10) };
    case CHANGE_SHIPPING_METHOD:
      return { ...state, shippingMethod: parseInt(action.payload, 10) };
    case CHANGE_TABLE_NUM:
      return { ...state, tableNum: action.payload };
    case CHANGE_ORDER_CART:
      return { ...state, orderCart: action.payload };
    case CHANGE_ORDER_CART_SPECIAL:
      return { ...state, orderCartSpecial: action.payload };
    case CHANGE_ORDER_MORE_CART:
      return { ...state, orderMoreCart: action.payload };
    case CHANGE_ORDER_DETAIL:
      return { ...state, orderDetail: action.payload };
    case CHANGE_ORDER_RELATED_BUZ_ID:
      return { ...state, relatedBuzId: action.payload };
    case CHANGE_ORDER_BILL_TYPE:
      return { ...state, billType: parseInt(action.payload, 10) };
    case CHANGE_ORDER_PREFERENCES:
      return { ...state, preference: action.payload };
    default:
      return { ...state };
  }
};
