import Client from './HttpClient';

const list = (merchantId, lng, lat) => {
  return Client.get(`/eagle-api/merchant/online/branches`, {
    merchantId,
    lng,
    lat,
  });
};

const find = (merchantId, branchId, lng, lat) => {
  return Client.get(
    `/eagle-api/merchant/online/${merchantId}/branches/${branchId}`,
    { lng, lat }
  );
};

const findById = (id, lng, lat) => {
  return Client.get(`/eagle-api/merchant/online/branches/${id}`, { lng, lat });
};

const configValue = (merchantId, key, moduleId) => {
  return Client.get(`/numbat-api/manage/merchant-setting/${merchantId}`, {
    key,
    moduleId,
  });
};

const configValuesByModule = (merchantId, moduleId) => {
  return Client.get(
    `/numbat-api/manage/merchant-setting/${merchantId}/list/${moduleId}`
  );
};

const getAdRichText = (merchantId, branchId) => {
  return Client.get(
    `/eagle-api/client/dishes/self/${merchantId}/${branchId}/setting`
  );
};

export default {
  list,
  find,
  findById,
  configValue,
  configValuesByModule,
  getAdRichText,
};
