/* eslint-disable import/no-cycle */
/* SETTINGS */
export const CHANGE_LOCALE = 'CHANGE_LOCALE';
export const CHANGE_GEOLOCATION = 'CHANGE_GEOLOCATION';
export const CHANGE_MERCHANT = 'CHANGE_MERCHANT';
export const CHANGE_BRANCH = 'CHANGE_BRANCH';
export const CHANGE_STORE = 'CHANGE_STORE';

/* AUTH */
export const LOGIN_USER_EMBEDDED = 'LOGIN_USER_EMBEDDED';
export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_ERROR = 'LOGIN_USER_ERROR';
export const REGISTER_USER = 'REGISTER_USER';
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS';
export const REGISTER_USER_ERROR = 'REGISTER_USER_ERROR';
export const LOGOUT_USER = 'LOGOUT_USER';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_ERROR = 'FORGOT_PASSWORD_ERROR';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR';

/* ONLINE ORDERING */
export const CHANGE_ORDER_SOURCE = 'CHANGE_ORDER_SOURCE';
export const CHANGE_ORDER_TYPE = 'CHANGE_ORDER_TYPE';
export const CHANGE_MEAL_TYPE = 'CHANGE_MEAL_TYPE';
export const CHANGE_TABLE_NUM = 'CHANGE_TABLE_NUM';
export const CHANGE_ORDER_CART = 'CHANGE_ORDER_CART';
export const CHANGE_ORDER_CART_SPECIAL = 'CHANGE_ORDER_CART_SPECIAL';
export const CHANGE_ORDER_MORE_CART = 'CHANGE_ORDER_MORE_CART';
export const CHANGE_SHIPPING_METHOD = 'CHANGE_SHIPPING_METHOD';
export const CHANGE_ORDER_DETAIL = 'CHANGE_ORDER_DETAIL';
export const CHANGE_ORDER_BILL_TYPE = 'CHANGE_ORDER_BILL_TYPE';
export const CHANGE_ORDER_PREFERENCES = 'CHANGE_ORDER_PREFERENCES';
export const CHANGE_ORDER_RELATED_BUZ_ID = 'CHANGE_ORDER_RELATED_BUZ_ID';

export * from './auth/actions';
export * from './settings/actions';
export * from './online-order/actions';
