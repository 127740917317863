/* eslint-disable import/no-cycle */
import {
  setCurrentLanguage,
  setCurrentGeolocation,
  setCurrentMerchantId,
  setCurrentBranchId,
  setCurrentStore,
} from '@/helpers/Utils';
import Validator from '@/helpers/Validator';
import {
  CHANGE_MERCHANT,
  CHANGE_BRANCH,
  CHANGE_LOCALE,
  CHANGE_GEOLOCATION,
  CHANGE_STORE,
} from '../actions';

export const changeLocale = (payload) => {
  setCurrentLanguage(payload);
  return {
    type: CHANGE_LOCALE,
    payload,
  };
};
export const changeGeolocation = (payload) => {
  setCurrentGeolocation(payload);
  return {
    type: CHANGE_GEOLOCATION,
    payload,
  };
};
export const changeMerchant = (payload) => {
  setCurrentMerchantId(payload);
  return {
    type: CHANGE_MERCHANT,
    payload,
  };
};
export const changeBranch = (payload) => {
  setCurrentBranchId(payload);
  return {
    type: CHANGE_BRANCH,
    payload,
  };
};
export const changeStore = (payload) => {
  const getClosedStatus = () => {
    let isClosed = true;
    payload.openingHourList.forEach((item) => {
      if (Validator.inTimeRange(item.startTime, item.endTime)) {
        isClosed = false;
      }
    });
    return isClosed;
  };

  setCurrentStore({ ...payload, isClosed: getClosedStatus() });
  return {
    type: CHANGE_STORE,
    payload: { ...payload, isClosed: getClosedStatus() },
  };
};
