// https://www.i18next.com/overview/configuration-options  使用的国际化类库
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import XHR from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { defaultLocale, version } from '@/configs/main';

const defaultLanguage = defaultLocale;
const lng = defaultLanguage;

i18n
  .use(XHR)
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    lng,
    defaultLanguage,
    supportedLngs: ['en-AU', 'zh-CN', 'zh-HK','vi-VN'],
    fallbackLng: defaultLanguage,
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json',
      queryStringParams:
        process.env.NODE_ENV === 'development' ? {} : { v: version },
    },
    ns: ['translations'],
    defaultNS: 'translations',
    debug: false,
    interpolation: {
      escapeValue: false,
    },
    react: {
      wait: true,
      useSuspense: true,
    },
  });

export default i18n;
