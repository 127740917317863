import Client from './HttpClient';

const list = (merchantId) => {
  return Client.get(`/eagle-api/manage-cardorder/${merchantId}/cardIssueRules`);
};

const orderDetail = (merchantId, id) => {
  return Client.get(
    `/eagle-api/manage-cardorder/${merchantId}/orderDetail/${id}`
  );
};

const place = (merchantId, payload) => {
  return Client.post(
    `/eagle-api/manage-cardorder/${merchantId}/createCardOrder`,
    payload
  );
};

const checkBalance = (merchantId, cardCode, password) => {
  return Client.post(
    `/eagle-api/manage-cardorder/${merchantId}/card/verification`,
    {
      cardCode,
      password,
    }
  );
};

const fPlace = (merchantId, payload) => {
  return Client.post(
    `/eagle-api/fast/cardorder/${merchantId}/createCardOrder`,
    payload
  );
};

const fCheckout = (payload) => {
  return Client.post(`/eagle-api/fast/cardorder/payment/payIntents`, payload);
};

const fPayState = (payload) => {
  return Client.post(`/eagle-api/fast/cardorder/payment/pay-state`, payload);
};

const fOrderDetail = (merchantId, id) => {
  return Client.get(
    `/eagle-api/fast/cardorder/${merchantId}/orderDetail/${id}`
  );
};

export default {
  list,
  orderDetail,
  place,
  checkBalance,
  fPlace,
  fCheckout,
  fPayState,
  fOrderDetail,
};
