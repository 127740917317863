/* eslint-disable lines-between-class-members */
import i18next from 'i18next';
import Enum from './Enum';

class ReservationCancelStatus extends Enum {
  PENDING = 1;
  SUCCESS = 2;
  FAIL = 3;

  labels() {
    const labels = {};
    labels[this.PENDING] = i18next.t('reservation.cancelStatus.pending');
    labels[this.FAIL] = i18next.t('reservation.cancelStatus.fail');
    labels[this.SUCCESS] = i18next.t('reservation.cancelStatus.success');
    return labels;
  }
}

export default new ReservationCancelStatus();
