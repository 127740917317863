import i18next from 'i18next';
import Enum from './Enum';

class PaymentMethod extends Enum {
  INSTORE = 1;
  ONLINE = 2;

  labels() {
    const labels = {};
    labels[this.INSTORE] = i18next.t('payment.instore');
    labels[this.ONLINE] = i18next.t('payment.online');
    return labels;
  }
}

export default new PaymentMethod();
