/* eslint-disable import/no-cycle */
import {
  defaultLocale,
  defaultColor,
  themeColorStorageKey,
  merchantIdKey,
  branchIdKey,
  storeKey,
} from '@/configs/main';
import Formatter from './Formatter';

export const getCurrentColor = () => {
  let currentColor = defaultColor;
  try {
    if (localStorage.getItem(themeColorStorageKey)) {
      currentColor = localStorage.getItem(themeColorStorageKey);
    }
  } catch (error) {
    console.log('>>> src/helpers/Utils.js -> getCurrentColor error:', error);
    currentColor = defaultColor;
  }
  return currentColor;
};

export const setCurrentColor = (color) => {
  try {
    localStorage.setItem(themeColorStorageKey, color);
  } catch (error) {
    console.log('>>> src/helpers/Utils.js -> setCurrentColor error:', error);
  }
};

export const getCurrentLanguage = () => {
  let language = defaultLocale;
  try {
    language = localStorage.getItem('currentLanguage');
    if (!language) {
      let currentLocale = window.navigator.language;
      const reg = /^zh(.*)(CN|cn|Cn)(.*)$/;
      if (currentLocale && currentLocale !== 'en' && reg.test(currentLocale)) {
        currentLocale = 'zh-CN';
      }
      const reg2 = /^zh(.*)(HK|hk|Hk|TW|tw|Tw)(.*)$/;
      if (currentLocale && currentLocale !== 'en' && reg2.test(currentLocale)) {
        currentLocale = 'zh-HK';
      }
      if (currentLocale) {
        language = currentLocale;
      }
    }
  } catch (error) {
    console.log(
      '>>>>: src/helpers/Utils.js : getCurrentLanguage -> error',
      error
    );
    language = defaultLocale;
  }
  return language;
};

export const setCurrentLanguage = (locale) => {
  try {
    localStorage.setItem('currentLanguage', locale);
  } catch (error) {
    console.log(
      '>>>>: src/helpers/Utils.js : setCurrentLanguage -> error',
      error
    );
  }
};

export const getCurrentMerchantId = () => {
  let data = '';
  try {
    data = sessionStorage.getItem(merchantIdKey);
  } catch (error) {
    console.log(
      '>>>>: src/helpers/Utils.js : getCurrentMerchantId -> error',
      error
    );
  }
  return data;
};

export const setCurrentMerchantId = (payload) => {
  try {
    sessionStorage.setItem(merchantIdKey, payload);
  } catch (error) {
    console.log(
      '>>>>: src/helpers/Utils.js : setCurrentMerchantId -> error',
      error
    );
  }
};

export const getCurrentBranchId = () => {
  let data = '';
  try {
    data = sessionStorage.getItem(branchIdKey);
  } catch (error) {
    console.log(
      '>>>>: src/helpers/Utils.js : getCurrentBranchId -> error',
      error
    );
  }
  return data;
};

export const setCurrentBranchId = (payload) => {
  try {
    sessionStorage.setItem(branchIdKey, payload);
  } catch (error) {
    console.log(
      '>>>>: src/helpers/Utils.js : setCurrentBranchId -> error',
      error
    );
  }
};

export const getCurrentStore = () => {
  let data = {};
  try {
    if (sessionStorage.getItem(storeKey)) {
      data = JSON.parse(sessionStorage.getItem(storeKey));
    }
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : getCurrentStore -> error', error);
  }
  return data;
};

export const setCurrentStore = (payload) => {
  try {
    sessionStorage.setItem(storeKey, JSON.stringify(payload));
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : setCurrentStore -> error', error);
  }
};

export const getCurrentGeolocation = () => {
  let geolocation = null;
  try {
    geolocation =
      sessionStorage.getItem('currentGeolocation') != null
        ? JSON.parse(sessionStorage.getItem('currentGeolocation'))
        : null;
  } catch (error) {
    console.log(
      '>>>>: src/helpers/Utils.js : getCurrentLocation -> error',
      error
    );
  }
  return geolocation;
};

export const setCurrentGeolocation = (geolocation) => {
  try {
    sessionStorage.setItem('currentGeolocation', JSON.stringify(geolocation));
  } catch (error) {
    console.log(
      '>>>>: src/helpers/Utils.js : setCurrentGeolocation -> error',
      error
    );
  }
};

export const getCurrentUser = () => {
  let user = null;
  try {
    user =
      localStorage.getItem('user') != null
        ? JSON.parse(localStorage.getItem('user'))
        : null;
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js  : getCurrentUser -> error', error);
    user = null;
  }
  return user;
};

export const setCurrentUser = (user) => {
  try {
    if (user) {
      localStorage.setItem('user', JSON.stringify(user));
    } else {
      localStorage.removeItem('user');
    }
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : setCurrentUser -> error', error);
  }
};

export const getCheckinStatus = (mid, bid) => {
  let checked = false;
  try {
    if (localStorage.getItem('checkinStatus') != null) {
      const records = JSON.parse(localStorage.getItem('checkinStatus'));
      const storeCheckinStatus = records.find(
        (el) => el.mid === mid && el.bid === bid
      );
      checked =
        storeCheckinStatus &&
        storeCheckinStatus.date === Formatter.date(new Date());
    }
  } catch (error) {
    console.log(
      '>>>>: src/helpers/Utils.js  : getCheckinStatus -> error',
      error
    );
  }
  return checked;
};

export const setCheckinStatus = (mid, bid) => {
  let records = [];
  try {
    if (localStorage.getItem('checkinStatus') != null) {
      records = JSON.parse(localStorage.getItem('checkinStatus'));
    }
    records.push({
      mid,
      bid,
      date: Formatter.date(new Date()),
    });
    localStorage.setItem('checkinStatus', JSON.stringify(records));
  } catch (error) {
    console.log(
      '>>>>: src/helpers/Utils.js : setCheckinStatus -> error',
      error
    );
  }
};

export const getGeoposition = () => {
  return new Promise((resolve, reject) => {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          if (position.coords) {
            const location = {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            };
            resolve(location);
          }
        },
        (error) => {
          reject(error);
        },
        {
          timeout: 10000,
        }
      );
    } else {
      reject(new Error('navigator geolocation undefinded.'));
    }
  });
};

export const generateUrlParams = (json) => {
  return Object.keys(json)
    .filter((key) => {
      if (json[key] || json[key] === 0 || json[key] === false) {
        return key;
      }
      return false;
    })
    .map((key) => {
      return `${encodeURIComponent(key)}=${encodeURIComponent(json[key])}`;
    })
    .join('&');
};

export const getQueryString = (name) => {
  const reg = new RegExp(`(^|&)${name}=([^&]*)(&|$)`);
  const r = window.location.search.substr(1).match(reg);
  if (r != null) return unescape(r[2]);
  return null;
};

export const getLocationSearch = () => {
  const url = window.location.search;
  const theRequest = {};
  if (url.indexOf('?') !== -1) {
    const str = url.substr(1);
    const strs = str.split('&');
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < strs.length; i++) {
      // eslint-disable-next-line prefer-destructuring
      theRequest[strs[i].split('=')[0]] = strs[i].split('=')[1];
    }
  }
  return theRequest;
};

export const getUA = () => {
  const userAgent = navigator.userAgent || '';
  if (/ziiforce_android/i.test(userAgent)) {
    return 'android';
  }
  if (/ziiforce_ios/i.test(userAgent)) {
    return 'ios';
  }
  return 'h5';
};

export const isInApp = () => {
  return /zii/i.test(navigator.userAgent || '');
};

export const isAndroid = () => {
  return /Android/i.test(navigator.userAgent || '');
};

export const isIPhone = () => {
  return /iPhone/i.test(navigator.userAgent || '');
};

export const isIPad = () => {
  return /iPad/i.test(navigator.userAgent || '');
};

export const isIOS = () => {
  return isIPhone() || isIPad();
};

export const isMobile = () => {
  return isIPhone() || isIPad() || isAndroid();
};

export const isMacOS = () => {
  return /Macintosh/i.test(navigator.userAgent || '');
};

export const isFlutter = () => {
  return /flutter/i.test(navigator.userAgent || '');
};

export const isWX = () => {
  return /MicroMessenger/i.test(navigator.userAgent || '');
};

export const isAlipay = () => {
  return /Alipay/i.test(navigator.userAgent || '');
};
