/* eslint-disable radix */
/* eslint-disable no-param-reassign */
import axios from 'axios';
import {
  getCurrentLanguage,
  getCurrentUser,
  setCurrentUser,
} from '@/helpers/Utils';
import { NotificationManager } from '@/components/common/react-notifications';

const client = axios.create({
  timeout: 30000,
});

// Allows Anonymous Access
const allows = [];

client.interceptors.request.use(
  (config) => {
    config.headers['systemcode'] = 1013;
    config.headers['timestamp'] = new Date().getTime();
    config.headers['Accept-Language'] = getCurrentLanguage();
    if (!allows.includes(config.url)) {
      const user = getCurrentUser();
      if (user) {
        config.headers['token'] = user.token;
      }
    }
    return config;
  },
  (error) => {
    // Do something with request error
    console.log(error); // for debug
    Promise.reject(error);
  }
);

client.interceptors.response.use(
  (response) => {
    const res = response.data;
    const code = parseInt(res.code, 10);
    if (code === 1000) {
      return Promise.resolve(res.data);
    }
    if (code === 1016) {
      NotificationManager.warning('Authentication failed, please re-login.');
      setCurrentUser(null);
      // window.location.href = '/login';
    }
    return Promise.reject(res);
  },
  (error) => {
    return Promise.reject(error);
  }
);

const get = (url, params = {}, options = {}) => {
  return client({ url, method: 'GET', params, options });
};

const post = (url, data = {}, params = {}, options = {}) => {
  return client({ url, method: 'POST', params, data, options });
};

const put = (url, data = {}, params = {}, options = {}) => {
  return client({ url, method: 'PUT', params, data, options });
};

const del = (url, params = {}, options = {}) => {
  return client({ url, method: 'DELETE', params, options });
};

export default {
  get,
  post,
  put,
  del,
};
