import Client from './HttpClient';

const list = (merchantId) => {
  return Client.get(`/eagle-api/online/payment/channels`, { merchantId });
};

const create = (
  orderId,
  channel,
  bankAccountId = null,
  transactions = 1,
  returnUrl = null,
  purpose = 1
) => {
  if (purpose === 3) {
    return Client.post('/eagle-api/manage-cardorder/payment/pay', {
      orderId,
      channel,
      bankAccountId,
    });
  }
  if (purpose === 4) {
    return Client.post('/eagle-api/membership-order/payment/pay', {
      orderId,
      channel,
      bankAccountId,
    });
  }
  let url = '';
  if (purpose === 1) {
    url = `/eagle-api/online/payment/pay`;
  }
  if (purpose === 2) {
    url = `/eagle-api/reservations/payment/pay`;
  }
  return Client.post(url, {
    orderId,
    channel,
    bankAccountId,
    transactions,
    returnUrl,
  });
};

const createIntent = (
  orderId,
  channel,
  transactions = 1,
  purpose = 1,
  saveCardId = false,
  returnUrl = null,
) => {
  if (purpose === 3) {
    return Client.post('/eagle-api/manage-cardorder/payment/payIntents', {
      orderId,
      channel,
      saveCardId,
    });
  }
  if (purpose === 4) {
    return Client.post('/eagle-api/membership-order/payment/payIntents', {
      orderId,
      channel,
      saveCardId,
    });
  }
  let url = '';
  if (purpose === 1) {
    url = '/eagle-api/online/payment/payIntents';
  }
  if (purpose === 2) {
    url = '/eagle-api/reservations/payment/payIntents';
  }
  return Client.post(url, {
    orderId,
    channel,
    transactions,
    saveCardId,
    returnUrl,
  });
};

const confirm = (orderId, paymentChannel, paymentApplyId, purpose = 1) => {
  const payload = {
    orderId,
    payState: 1,
    paymentChannel,
    paymentApplyId,
  };
  let url = '';
  if (purpose === 1) {
    url = `/eagle-api/online/order/pay-state`;
  }
  if (purpose === 2) {
    url = `/eagle-api/reservations/pay-state`;
  }
  if (purpose === 3) {
    url = `/eagle-api/manage-cardorder/payment/pay-state`;
    delete payload.paymentApplyId;
  }
  if (purpose === 4) {
    url = `/eagle-api/membership-order/payment/pay-state`;
    delete payload.paymentApplyId;
  }
  return Client.post(url, payload);
};

const cancel = (orderId, paymentChannel, paymentApplyId, purpose = 1) => {
  const payload = {
    orderId,
    payState: 2,
    paymentChannel,
    paymentApplyId,
  };
  let url = '';
  if (purpose === 1) {
    url = `/eagle-api/online/order/pay-state`;
  }
  if (purpose === 2) {
    url = `/eagle-api/reservations/pay-state`;
  }
  if (purpose === 3) {
    url = `/eagle-api/manage-cardorder/payment/pay-state`;
    delete payload.paymentApplyId;
  }
  if (purpose === 4) {
    url = `/eagle-api/membership-order/payment/pay-state`;
    delete payload.paymentApplyId;
  }
  return Client.post(url, payload);
};

const cancelOrder = (orderId) => {
  return Client.post(`/eagle-api/dishes/order/cancel/${orderId}`);
};

const payAtCounter = (orderId) => {
  return Client.post(`/eagle-api/dishes/order/counterPay/${orderId}`);
};

// {"merchantId":"xx","branchId":"xxx","channel":7,"saveCardId":false,"payAmount":6.79}
const prepay = (payload) => {
  return Client.post(`/eagle-api/online/payment/preparePay`, payload);
};

export default {
  list,
  create,
  createIntent,
  confirm,
  cancel,
  cancelOrder,
  payAtCounter,
  prepay,
};
