import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { user } from '@/services';
import { setCurrentUser } from '@/helpers/Utils';
import moment from 'moment';
import {
  LOGIN_USER,
  LOGOUT_USER,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  LOGIN_USER_EMBEDDED,
} from '../actions';

import {
  loginUserSuccess,
  loginUserError,
  forgotPasswordSuccess,
  forgotPasswordError,
  resetPasswordSuccess,
  resetPasswordError,
} from './actions';

const loginWithVerifyCodeAsync = (
  loginCode,
  type,
  code,
  shareCode,
  merchantId
) =>
  user
    .loginByVerifyCode(loginCode, type, code, shareCode, merchantId)
    .then((u) => u)
    .catch((error) => {
      throw error;
    });

const getProfileAsync = (token) =>
  user
    .profile(token)
    .then((data) => data)
    .catch((error) => {
      throw error;
    });

function* loginWithVerifyCode({ payload }) {
  const {
    user: { loginCode, type, code, shareCode, merchantId },
    redirectUrl,
    history,
    needRedirect,
    sso,
  } = payload;
  try {
    const loginUser = yield call(
      loginWithVerifyCodeAsync,
      loginCode,
      type,
      code,
      shareCode,
      merchantId
    );
    if (sso) {
      window.location.href = `${redirectUrl}?token=${loginUser.token}&uid=${loginUser.customerId}`;
      return;
    }
    const profile = yield call(
      getProfileAsync,
      loginUser.customerId,
      loginUser.token
    );
    setCurrentUser({
      ...loginUser,
      ...profile,
      birthday: profile.birthday
        ? moment(profile.birthday, 'YYYY-MM-DD').format('DD/MM/YYYY')
        : '',
    });
    yield put(
      loginUserSuccess({
        ...loginUser,
        ...profile,
        birthday: profile.birthday
          ? moment(profile.birthday, 'YYYY-MM-DD').format('DD/MM/YYYY')
          : '',
      })
    );
    if (needRedirect) {
      history.push(redirectUrl);
    }
  } catch (error) {
    yield put(loginUserError(error.message));
  }
}

export function* watchLoginUser() {
  yield takeEvery(LOGIN_USER, loginWithVerifyCode);
}

function* loginEmbedded({ payload }) {
  setCurrentUser(payload);
  yield put(loginUserSuccess(payload));
}

export function* watchLoginUserEmbedded() {
  yield takeEvery(LOGIN_USER_EMBEDDED, loginEmbedded);
}

const logoutAsync = async (history, goHome) => {
  setCurrentUser();
  if (history && goHome) {
    history.push('/');
  }
};

function* logout({ payload }) {
  const { history, goHome } = payload;
  yield call(logoutAsync, history, goHome);
}

export function* watchLogoutUser() {
  yield takeEvery(LOGOUT_USER, logout);
}

const forgotPasswordAsync = async (email) => {
  console.log(email);
};

function* forgotPassword({ payload }) {
  const { email } = payload.forgotUserMail;
  try {
    const forgotPasswordStatus = yield call(forgotPasswordAsync, email);
    if (!forgotPasswordStatus) {
      yield put(forgotPasswordSuccess('success'));
    } else {
      yield put(forgotPasswordError(forgotPasswordStatus.message));
    }
  } catch (error) {
    yield put(forgotPasswordError(error));
  }
}

export function* watchForgotPassword() {
  yield takeEvery(FORGOT_PASSWORD, forgotPassword);
}

const resetPasswordAsync = async (resetPasswordCode, newPassword) => {
  console.log(resetPasswordCode, newPassword);
};

function* resetPassword({ payload }) {
  const { newPassword, resetPasswordCode } = payload;
  try {
    const resetPasswordStatus = yield call(
      resetPasswordAsync,
      resetPasswordCode,
      newPassword
    );
    if (!resetPasswordStatus) {
      yield put(resetPasswordSuccess('success'));
    } else {
      yield put(resetPasswordError(resetPasswordStatus.message));
    }
  } catch (error) {
    yield put(resetPasswordError(error));
  }
}

export function* watchResetPassword() {
  yield takeEvery(RESET_PASSWORD, resetPassword);
}

export default function* rootSaga() {
  yield all([
    fork(watchLoginUser),
    fork(watchLoginUserEmbedded),
    fork(watchLogoutUser),
    fork(watchForgotPassword),
    fork(watchResetPassword),
  ]);
}
