import Client from './HttpClient';

const validationCode = (loginCode, type, scene) => {
  return Client.post(`/wombat-api/auth/verification-code`, {
    loginCode,
    type,
    scene,
  });
};

const checkValidationCode = (loginCode, type, verifyCode) => {
  return Client.post(`/wombat-api/auth/verification-code-check`, {
    loginCode,
    type,
    verifyCode,
  });
};

const androidDownloadUrl = () => {
  return Client.get(`/wombat-api/client-version/version`, {
    clientName: 'ANDROID',
    version: 1,
  });
};


export default {
  validationCode,
  checkValidationCode,
  androidDownloadUrl,
};
