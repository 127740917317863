import Enum from './Enum';

class GiftCardAmountType extends Enum {
  FIXED = 1;

  CUSTOM = 2;

  labels() {
    const labels = {};
    labels[this.FIX] = 'Fixed';
    labels[this.CUS] = 'Custom';
    return labels;
  }
}

export default new GiftCardAmountType();
