import Enum from './Enum';

class GiftCardAmountType extends Enum {
  PHYSICAL = 1;

  ELECTRONIC = 2;

  labels() {
    const labels = {};
    labels[this.PHYSICAL] = 'Physical';
    labels[this.ELECTRONIC] = 'Electronic';
    return labels;
  }
}

export default new GiftCardAmountType();
