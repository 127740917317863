/* eslint-disable import/no-cycle */
import { ShippingMethod } from '@/enums';
import {
  setOrderSource,
  setOrderType,
  setMealType,
  setShippingMethod,
  setTableNum,
  setOrderCart,
  setOrderMoreCart,
  setOrderDetail,
  setOrderRelatedBuzId,
  setOrderBillType,
  setOrderPreference,
  setOrderCartSpecial,
} from '@/helpers/OrderUtil';
import {
  CHANGE_ORDER_TYPE,
  CHANGE_ORDER_SOURCE,
  CHANGE_MEAL_TYPE,
  CHANGE_SHIPPING_METHOD,
  CHANGE_TABLE_NUM,
  CHANGE_ORDER_CART,
  CHANGE_ORDER_MORE_CART,
  CHANGE_ORDER_DETAIL,
  CHANGE_ORDER_RELATED_BUZ_ID,
  CHANGE_ORDER_BILL_TYPE,
  CHANGE_ORDER_PREFERENCES,
} from '../actions';

export const changeOrderType = (payload) => {
  setOrderType(payload);
  return {
    type: CHANGE_ORDER_TYPE,
    payload,
  };
};

export const changeOrderSource = (payload) => {
  setOrderSource(payload);
  return {
    type: CHANGE_ORDER_SOURCE,
    payload,
  };
};
export const changeMealType = (payload) => {
  setMealType(payload);
  return {
    type: CHANGE_MEAL_TYPE,
    payload,
  };
};
export const changeShippingMethod = (payload) => {
  setShippingMethod(payload);
  return {
    type: CHANGE_SHIPPING_METHOD,
    payload: payload ?? ShippingMethod.PICKUP,
  };
};
export const changeTableNum = (payload) => {
  setTableNum(payload);
  return {
    type: CHANGE_TABLE_NUM,
    payload,
  };
};
export const changeOrderCart = (mid, bid, s, payload) => {
  setOrderCart(mid, bid, s, payload);
  const cart = payload ? { ...payload, s, mid, bid } : null;
  return {
    type: CHANGE_ORDER_CART,
    payload: cart,
  };
};

export const changeOrderCartSpecial = (mid, bid, s, payload) => {
  setOrderCartSpecial(mid, bid, s, payload);
  const cart = payload ? { ...payload, s, mid, bid } : null;
  return {
    type: CHANGE_ORDER_CART,
    payload: cart,
  };
};
export const changeOrderMoreCart = (mid, bid, s, payload) => {
  setOrderMoreCart(mid, bid, s, payload);
  const cart = payload ? { ...payload, s, mid, bid } : null;
  return {
    type: CHANGE_ORDER_MORE_CART,
    payload: cart,
  };
};
export const changeOrderDetail = (payload) => {
  setOrderDetail(payload);
  return {
    type: CHANGE_ORDER_DETAIL,
    payload,
  };
};
export const changeOrderRelatedBuzId = (payload) => {
  setOrderRelatedBuzId(payload);
  return {
    type: CHANGE_ORDER_RELATED_BUZ_ID,
    payload,
  };
};
export const changeOrderBillType = (payload) => {
  setOrderBillType(payload);
  return {
    type: CHANGE_ORDER_BILL_TYPE,
    payload,
  };
};
export const changeOrderPreference = (payload) => {
  setOrderPreference(payload);
  return {
    type: CHANGE_ORDER_PREFERENCES,
    payload,
  };
};
