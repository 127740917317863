import { tableLocked } from '@/assets/image/assetsImages';
import Swal from '@/components/SweetAlert2';
import { categoryMenuKey, rootOrderKey } from '@/configs/main';
import { getOrderCart } from '@/helpers/OrderUtil';
import i18next from 'i18next';
import { PaymentChannel } from '@/enums';
import Client from './HttpClient';
// 主菜单
const groupCategoryItems = (groupCode) => {
  const url = `/ziipos-api/product/group-category-items`;
  return Client.get(url, { groupCode });
};

// 子菜单
const submenuLinkRelation = (productId) => {
  const url = `/ziipos-api/product/submenu-link-relation`;
  return Client.get(url, { productId });
};

// AppOnlineOrder(1, "app订单"),
// H5OnlineOrder(2, "H5订单"), //onlineOrder
// MTPartner(4, "MT合作渠道"),
// QROnlineOrder(5, "扫码点餐");
// onlineOrder主菜单
const olGroupCategoryItems = (merchantId, branchId, orderSourceType = 3) => {
  const url = `/eagle-api/dishes/${merchantId}/${branchId}/category-menu`;
  return Client.get(url, { orderSourceType });
};

// onlineOrder子菜单
const olSubmenuLinkRelation = (
  merchantId,
  branchId,
  productId,
  orderSourceType = 3
) => {
  const url = `/eagle-api/dishes/${merchantId}/${branchId}/sub-link-relate/${productId}`;
  return Client.get(url, { orderSourceType });
};

// onlineOrder Instruction
const olInstructionRelate = (
  merchantId,
  branchId,
  productId,
  orderSourceType = 3
) => {
  const url = `/eagle-api/dishes/${merchantId}/${branchId}/menu-item-relate/${productId}`;
  return Client.get(url, { orderSourceType });
};

const olTableOrder = (merchantId, branchId, tableNo) => {
  return Client.get(`/eagle-api/dishes/order/table-order`, {
    merchantId,
    branchId,
    tableNo,
  });
};

const olTableCheckLock = (merchantId, branchId, tableNo) => {
  return Client.get(`/eagle-api/dishes/table/check-lock`, {
    merchantId,
    branchId,
    tableNo,
  });
};

const olFind = (orderId) => {
  return Client.get(`/eagle-api/dishes/order/${orderId}`, {});
};

const olPrePlace = (
  merchantId,
  branchId,
  orderItemList = [],
  orderSourceType = 3,
  mealType = 2,
  deliveryMethod,
  orderDate = null,
  deliveryTimeId = null,
  addressId = null,
  useCouponId = null,
  usePointEqAmount = 0,
  useBalance = 0,
  tableNo = null,
  tipsRate = 0
) => {
  return Client.post('/eagle-api/dishes/order/try-calculation', {
    merchantId,
    branchId,
    orderItemList,
    orderSourceType,
    mealType,
    orderDate,
    deliveryTimeId,
    deliveryMethod,
    addressId,
    useCouponId,
    usePointEqAmount,
    useBalance,
    tableNo,
    tipsRate,
  });
};

const olPlace = (
  merchantId,
  branchId,
  relatedBuzNo,
  deliveryMethod,
  paymentMethod,
  orderItemList,
  orderDate = null,
  deliveryTimeId = null,
  addressId = null,
  note = '',
  useCouponId = null,
  usePointEqAmount = 0,
  useBalance = 0,
  orderSourceType = 3,
  mealType = 2,
  tableNo,
  tipsRate = 0,
  preparePayId = '',
  bindPreparePay = false,
  paymentChannel = PaymentChannel.STRIPE
) => {
  return Client.post('/eagle-api/dishes/order/place', {
    merchantId,
    branchId,
    relatedBuzNo,
    deliveryMethod,
    paymentMethod,
    orderItemList,
    orderDate,
    deliveryTimeId,
    addressId,
    note,
    useCouponId,
    usePointEqAmount,
    useBalance,
    orderSourceType,
    mealType,
    tableNo,
    tipsRate,
    preparePayId,
    bindPreparePay,
    paymentChannel,
  });
};

// merchantId,
// branchId,
// relatedBuzNo,
// deliveryMethod,
// paymentMethod,
// orderItemList,
// orderDate = null,
// deliveryTimeId = null,
// addressId = null,
// note = '',
// useCouponId = null,
// usePointEqAmount = 0,
// useBalance = 0,
// orderSourceType = 3,
// mealType = 2,
// tableNo

const olPlace2 = (payload) => {
  return Client.post('/eagle-api/dishes/order/place', payload);
};

// 查看锁桌状态
const showTableLockedAlert = () => {
  Swal.fire({
    imageUrl: tableLocked,
    imageWidth: 200,
    text: i18next.t('restaurant.notice.excess-time'), // 显示内容
    showCancelButton: false, // 是否显示取消按钮
    showConfirmButton: false,
    allowOutsideClick: false,
  });
};

// 查看当前菜最多选几个
const checkMenuItemMaxCount = (sourceItem, qty, mid, bid, source) => {
  const count = qty ?? 0;
  let countByOrder = 0;
  let countByCart = 0;
  const rootOrderKeyJson = sessionStorage.getItem(rootOrderKey);
  if (rootOrderKeyJson != null) {
    const rootOrder = JSON.parse(rootOrderKeyJson);
    if (rootOrder) {
      rootOrder.orderItemList.forEach((item) => {
        if (sourceItem.productId === item.productId) {
          countByOrder += item.selQty ?? item.qty;
        }
      });
    }
  }
  const cartNode = getOrderCart(mid, bid, source);
  if (cartNode) {
    cartNode.children.forEach((item) => {
      if (sourceItem.productId === item.productId) {
        countByCart += item.selQty ?? item.qty;
      }
    });
  }
  const totalQty = count + countByOrder + countByCart;
  if (sourceItem.maximumQty > 0 && totalQty > sourceItem.maximumQty) {
    Swal.fire(
      i18next.t('restaurant.limitRulesAlert.at-most-product', {
        maxCount: sourceItem.maximumQty,
        productName: sourceItem.productName,
      })
    );
    return false;
  }
  return true;
};

// 查看Category中的菜最多选几个
const checkCategoryItemMaxCount = (categoryId, qty, mid, bid, source) => {
  const categoryItemList =
    JSON.parse(sessionStorage.getItem(categoryMenuKey)) ?? [];
  const categoryItem = categoryItemList.find(
    (element) => categoryId === element.categoryId
  );
  if (categoryItem) {
    const rootOrder = JSON.parse(sessionStorage.getItem(rootOrderKey));
    const cartNode = getOrderCart(mid, bid, source);
    const count = qty ?? 0;
    let countByOrder = 0;
    let countByCart = 0;
    if (rootOrder) {
      rootOrder.orderItemList.forEach((item) => {
        if (categoryId === item.categoryId) {
          countByOrder += item.selQty ?? item.qty;
        }
      });
    }
    if (cartNode) {
      cartNode.children.forEach((item) => {
        if (categoryId === item.categoryId) {
          countByCart += item.selQty ?? item.qty;
        }
      });
    }
    const totalQty = count + countByOrder + countByCart;
    if (
      categoryItem.maximumChoiceQty > 0 &&
      totalQty > categoryItem.maximumChoiceQty
    ) {
      Swal.fire(
        i18next.t('restaurant.limitRulesAlert.at-most-of-category', {
          maxCount: categoryItem.maximumChoiceQty,
          categoryName: categoryItem.displayName,
        })
      );
      return false;
    }
  }
  return true;
};

// 查看所有Category中的菜最少选几个
const checkAllCategoryItemMinCount = (balanceItemList) => {
  const categoryItemList =
    JSON.parse(sessionStorage.getItem(categoryMenuKey)) ?? [];
  const itemList = [];
  const rootOrder = JSON.parse(sessionStorage.getItem(rootOrderKey));
  categoryItemList.forEach((category) => {
    let count = 0;
    balanceItemList.forEach((item) => {
      if (category.categoryId === item.categoryId) {
        count += item.selQty ?? item.qty;
      }
    });
    if (rootOrder) {
      rootOrder.orderItemList.forEach((item) => {
        if (category.categoryId === item.categoryId) {
          count += item.selQty ?? item.qty;
        }
      });
    }
    if (category.minimumChoiceQty > 0 && count < category.minimumChoiceQty) {
      itemList.push(category);
    }
  });
  return itemList;
};

// 查看当前菜库存数量
const checkMenuItemStockCount = (sourceItem, qty, mid, bid, source) => {
  const count = qty ?? 0;
  let countByOrder = 0;
  let countByCart = 0;
  // const rootOrderKeyJson = sessionStorage.getItem(rootOrderKey);
  // if (rootOrderKeyJson != null) {
  //   const rootOrder = JSON.parse(rootOrderKeyJson);
  //   if (rootOrder) {
  //     rootOrder.orderItemList.forEach((item) => {
  //       if (sourceItem.productId === item.productId) {
  //         countByOrder += item.selQty ?? item.qty;
  //       }
  //     });
  //   }
  // }
  const cartNode = getOrderCart(mid, bid, source);
  if (cartNode) {
    cartNode.children.forEach((item) => {
      if (sourceItem.productId === item.productId) {
        countByCart += item.selQty ?? item.qty;
      }
    });
  }
  const totalQty = count + countByOrder + countByCart;
  if (
    sourceItem.controlFlag &&
    sourceItem.stock > 0 &&
    totalQty > sourceItem.stock
  ) {
    Swal.fire(i18next.t('restaurant.limitRulesAlert.overStock'));
    return false;
  }
  return true;
};

const menuItemSoldOut = (item) => {
  if (
    item.soldOut
    // || (item.controlFlag &&
    //   (item.stock === 0 || item.stock < (item.defaultQty ?? 0)))
  ) {
    return true;
  }
  return false;
};

const policy = (merchantId) => {
  return Client.get(`/eagle-api/online/${merchantId}/merchantPolicy`);
};

const windcavePaymentResult = (merchantId, branchId, sessionId) => {
  return Client.get(`/eagle-api/online/payment/windcave/paymentResult`, { merchantId, branchId, sessionId });
};

export default {
  groupCategoryItems,
  submenuLinkRelation,
  olGroupCategoryItems,
  olSubmenuLinkRelation,
  olInstructionRelate,
  olFind,
  olPrePlace,
  olPlace,
  olPlace2,
  olTableOrder,
  showTableLockedAlert,
  checkMenuItemMaxCount,
  checkCategoryItemMaxCount,
  checkAllCategoryItemMinCount,
  checkMenuItemStockCount,
  menuItemSoldOut,
  olTableCheckLock,
  policy,
  windcavePaymentResult,
};
