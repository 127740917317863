import i18next from 'i18next';
import Enum from './Enum';

class PointMode extends Enum {
  MONEY = 1;

  PRODUCT = 2;

  labels() {
    const labels = {};
    labels[this.MONEY] = i18next.t('pointMode.money');
    labels[this.PRODUCT] = i18next.t('pointMode.product');
    return labels;
  }
}

export default new PointMode();
