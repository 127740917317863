/* eslint-disable lines-between-class-members */
import i18next from 'i18next';
import Enum from './Enum';

class OrderType extends Enum {
  MON = 1;
  TUE = 2;
  WED = 3;
  THU = 4;
  FRI = 5;
  SAT = 6;
  SUN = 7;
  labels() {
    const labels = {};
    labels[this.MON] = i18next.t('common.dayOfWeek.Mon');
    labels[this.TUE] = i18next.t('common.dayOfWeek.Tue');
    labels[this.WED] = i18next.t('common.dayOfWeek.Wed');
    labels[this.THU] = i18next.t('common.dayOfWeek.Thu');
    labels[this.FRI] = i18next.t('common.dayOfWeek.Fri');
    labels[this.SAT] = i18next.t('common.dayOfWeek.Sat');
    labels[this.SUN] = i18next.t('common.dayOfWeek.Sun');
    return labels;
  }
}

export default new OrderType();
