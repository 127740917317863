import i18next from 'i18next';
import Enum from './Enum';

class OrderItemStatus extends Enum {
  NORMAL = 0;
  REFUNDED = 1;
  CHANGED = 2;
  INSTEAD = 3;

  labels() {
    const labels = {};
    labels[this.NORMAL] = i18next.t('orderItem.status.normal');
    labels[this.REFUNDED] = i18next.t('orderItem.status.refunded');
    labels[this.CHANGED] = i18next.t('orderItem.status.changed');
    labels[this.INSTEAD] = i18next.t('orderItem.status.instead');
    return labels;
  }
}

export default new OrderItemStatus();
