import md5 from 'js-md5';
import Client from './HttpClient';

const login = (loginCode, type, password) => {
  return Client.post(`/wombat-api/auth/pwd-login`, {
    loginCode,
    type,
    password: md5(password),
  });
};

const loginByVerifyCode = (
  loginCode,
  type,
  verifyCode,
  shareCode,
  merchantId
) => {
  return Client.post(`/wombat-api/auth/verify-code-login`, {
    loginCode,
    type,
    verifyCode,
    shareCode,
    merchantId,
  });
};

const setContact = (customerId, contact, type, verifyCode) => {
  return Client.post(`/wombat-api/user-info/${customerId}/contacts`, {
    contact,
    type,
    verifyCode,
  });
};

const setPassword = (customerId, password) => {
  return Client.post(`/wombat-api/auth/passwords`, {
    customerId,
    password: md5(password),
  });
};

const changePassword = (customerId, oldPassword, password) => {
  return Client.put(`/wombat-api/auth/passwords/${customerId}`, {
    oldPassword: md5(oldPassword),
    password: md5(password),
  });
};

const resetPassword = (type, loginCode, verifyCode, password) => {
  return Client.post(`/wombat-api/auth/password-forgot`, {
    loginCode,
    type,
    verifyCode,
    password: md5(password),
  });
};

const setPaymentPassword = (customerId, paymentPwd) => {
  return Client.post(`/wombat-api/auth/payment-passwords`, {
    customerId,
    paymentPwd: md5(paymentPwd),
  });
};

const checkPaymentPassword = (customerId, paymentPwd) => {
  return Client.post(`/wombat-api/auth/payment-password-check/${customerId}`, {
    paymentPwd: md5(paymentPwd),
  });
};

const changePaymentPassword = (customerId, oldPaymentPwd, paymentPwd) => {
  return Client.put(`/wombat-api/auth/payment-passwords/${customerId}`, {
    oldPaymentPwd: md5(oldPaymentPwd),
    paymentPwd: md5(paymentPwd),
  });
};

const resetPaymentPassword = (type, loginCode, verifyCode, paymentPwd) => {
  return Client.post(`/wombat-api/auth/payment-password-forgot`, {
    loginCode,
    type,
    verifyCode,
    paymentPwd: md5(paymentPwd),
  });
};

const profile = (customerId, token = '') => {
  return Client.get(
    `/wombat-api/user-info/customers/${customerId}`,
    {},
    {
      headers: {
        Token: token,
      },
    }
  );
};

const updateProfile = (customerId, alias, avatar, gender) => {
  return Client.put(`/wombat-api/user-info/customers/${customerId}`, {
    alias,
    avatar,
    gender,
  });
};

const updateBaseInfo = (customerId, fieldName, fieldValue) => {
  return Client.put(`/wombat-api/user-info/customer-fields/${customerId}`, {
    fieldName,
    fieldValue,
  });
};

const updateContact = (customerId, contact, type, verifyCode) => {
  return Client.post(`/wombat-api/user-info/${customerId}/contacts`, {
    contact,
    type,
    verifyCode,
  });
};

export default {
  login,
  loginByVerifyCode,
  setContact,
  setPassword,
  changePassword,
  resetPassword,
  setPaymentPassword,
  checkPaymentPassword,
  changePaymentPassword,
  resetPaymentPassword,
  profile,
  updateProfile,
  updateBaseInfo,
  updateContact,
};
