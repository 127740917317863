import Enum from './Enum';
import i18n from 'i18next';

class ShippingMethod extends Enum {
  PICKUP = 1;

  DELIVERY = 2;

  labels() {
    const labels = {};
    labels[this.PICKUP] = i18n.t('order.type.pickUp');
    labels[this.DELIVERY] = i18n.t('order.type.delivery');
    return labels;
  }
}

export default new ShippingMethod();
