import {
  getCurrentLanguage,
  getCurrentGeolocation,
  getCurrentMerchantId,
  getCurrentBranchId,
  getCurrentStore,
} from '@/helpers/Utils';
import {
  CHANGE_LOCALE,
  CHANGE_MERCHANT,
  CHANGE_BRANCH,
  CHANGE_GEOLOCATION,
  CHANGE_STORE,
} from '../actions';

const INIT_STATE = {
  locale: getCurrentLanguage(),
  geolocation: getCurrentGeolocation(),
  mid: getCurrentMerchantId(),
  bid: getCurrentBranchId(),
  store: getCurrentStore(),
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case CHANGE_LOCALE:
      return { ...state, locale: action.payload };
    case CHANGE_GEOLOCATION:
      return { ...state, geolocation: action.payload };
    case CHANGE_MERCHANT:
      return { ...state, mid: action.payload };
    case CHANGE_BRANCH:
      return { ...state, bid: action.payload };
    case CHANGE_STORE:
      return { ...state, store: action.payload };
    default:
      return { ...state };
  }
};
