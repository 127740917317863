/* eslint-disable lines-between-class-members */
import i18next from 'i18next';
import Enum from './Enum';

class OrderStatus extends Enum {
  UNPAID = 1;
  PENDING = 2;
  PREPARING = 3;
  PREPARED = 4;
  DELIVERING = 5;
  COMPLETED = 6;
  CANCELED = 7;
  INVALID = 8;
  RETURNED = 9;
  CHECKING = 10;
  ACCEPT_FAIL = 11;

  labels() {
    const labels = {};
    labels[this.UNPAID] = i18next.t('order.status.pendingPayment');
    labels[this.PENDING] = i18next.t('order.status.pendingAccept');
    labels[this.PREPARING] = i18next.t('order.status.preparing');
    labels[this.PREPARED] = i18next.t('order.status.prepared');
    labels[this.DELIVERING] = i18next.t('order.status.shipping');
    labels[this.COMPLETED] = i18next.t('order.status.completed');
    labels[this.CANCELED] = i18next.t('order.status.canceled');
    labels[this.INVALID] = i18next.t('order.status.invalid');
    labels[this.RETURNED] = i18next.t('order.status.returned');
    labels[this.CHECKING] = i18next.t('order.status.checkingPayment');
    labels[this.ACCEPT_FAIL] = i18next.t('order.status.acceptFail');
    return labels;
  }
}

export default new OrderStatus();
