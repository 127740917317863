/* eslint-disable lines-between-class-members */
import i18next from 'i18next';
import Enum from './Enum';

class ReservationStatus extends Enum {
  INITIAL = 0;
  PLACED = 1;
  PENDING = 2;
  ACCEPTED = 3;
  SEATED = 4;
  COMPLETED = 5;
  CANCELED = 6;

  labels() {
    const labels = {};
    labels[this.INITIAL] = i18next.t('reservation.status.initial');
    labels[this.PLACED] = i18next.t('reservation.status.placed');
    labels[this.PENDING] = i18next.t('reservation.status.pending');
    labels[this.ACCEPTED] = i18next.t('reservation.status.accepted');
    labels[this.SEATED] = i18next.t('reservation.status.seated');
    labels[this.COMPLETED] = i18next.t('reservation.status.completed');
    labels[this.CANCELED] = i18next.t('reservation.status.canceled');
    return labels;
  }
}

export default new ReservationStatus();
