import { getStripeKeyIntent } from '@/helpers/WorkspaceHelper';
import Client from './HttpClient';

const list = (channel) => {
  const url = '/eagle-api/online/payment/channel/bank-list';
  const payKeyIntention = getStripeKeyIntent();
  return Client.get(url, { channel, payKeyIntention });
};

const create = (channel, bankNo, expMonth, expYear, cvc) => {
  const url = '/eagle-api/online/payment/channel/bank';
  return Client.post(url, {
    channel,
    bankNo,
    expMonth,
    expYear,
    cvc,
  });
};

const setupIntent = (channel, saveForFuture) => {
  const payKeyIntention = getStripeKeyIntent();
  const url = '/eagle-api/online/payment/channel/setup-intent';
  return Client.post(url, { channel, usage: +saveForFuture, payKeyIntention });
};

const confirmSetupIntent = (channel, setupIntentId, paymentMethodId) => {
  const url = '/eagle-api/online/payment/channel/setup-intent/confirm';
  return Client.post(url, { channel, setupIntentId, paymentMethodId });
};

const cancelSetupIntent = (channel, setupIntentId) => {
  const url = '/eagle-api/online/payment/channel/setup-intent/cancel';
  return Client.post(url, { channel, setupIntentId });
};

const remove = (channel, bankAccountId) => {
  const url = '/eagle-api/online/payment/channel/payment-method/detach';
  return Client.post(url, {
    channel,
    bankAccountId,
  });
};

export default {
  list,
  create,
  remove,
  setupIntent,
  confirmSetupIntent,
  cancelSetupIntent,
};
