import axios from 'axios';

const getAppleWalletPass = (customerId, merchantId) => {
  return axios({
    url: `/mole/wallet/apple/${merchantId}/${customerId}`,
    method: 'POST',
    responseType: 'blob',
  });
};

export default {
  getAppleWalletPass,
};
