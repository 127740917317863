import Enum from './Enum';

class GiftCardOrderStatus extends Enum {
  INIT = 1;

  PAID = 3;

  GENERATED = 4;

  COMPLETED = 5;

  REFUNDED = 6;

  labels() {
    const labels = {};
    labels[this.INIT] = 'Placed';
    labels[this.PAID] = 'Paid';
    labels[this.GENERATED] = 'Processing';
    labels[this.COMPLETED] = 'Completed';
    labels[this.REFUNDED] = 'Refunded';
    return labels;
  }
}

export default new GiftCardOrderStatus();
