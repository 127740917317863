import head from './head.png';
import check from './check.png';
import uncheck from './uncheck.png';
import facebook from './facebook.png';
import twitter from './twitter.png';
import google from './google.png';
import logo from './logo.png';
import qrcode from './qrcode.png';
import cardbg from './cardbg.png';
import infoIcon from './infoIcon.png';
import tabbg from './tabbg.png';
import goto from './goto.png';
import fail from './fail.png';
import tableLocked from './table_locked.png';
import success from './success.png';
import detailIcon1 from './icon/icon1.png';
import detailIcon2 from './icon/icon2.png';
import detailIcon3 from './icon/icon3.png';
import detailIcon4 from './icon/icon4.png';
import expandIcon from './icon/expand.png';
import dineInIcon from './dine_in.png';
import takeAwayIcon from './take_away.png';
import loginIcon from './login-icon.svg';
import noOrder from './no_order.png';
import appleWallet from './wallet-icon/apple.svg';
import googleWallet from './wallet-icon/google.svg';
import appstore from './download-icon/ios.svg';
import playstore from './download-icon/android.png';

export {
  head,
  check,
  uncheck,
  facebook,
  twitter,
  google,
  logo,
  qrcode,
  cardbg,
  tabbg,
  infoIcon,
  goto,
  fail,
  tableLocked,
  success,
  detailIcon1,
  detailIcon2,
  detailIcon3,
  detailIcon4,
  expandIcon,
  dineInIcon,
  takeAwayIcon,
  loginIcon,
  noOrder,
  appleWallet,
  googleWallet,
  appstore,
  playstore,
};
