class Enum {
  // eslint-disable-next-line class-methods-use-this
  labels() {
    return {};
  }

  getLabel(value) {
    return this.labels()[value] ?? '--';
  }

  getSelectOptions() {
    return Object.keys(this.labels()).map((key) => {
      const value = Number(key);
      if (!Number.isNaN(value)) {
        return { value, label: this.getLabel(key) };
      }
      return { value: key, label: this.getLabel(key) };
    });
  }

  getSelectedOption(value) {
    return { value, label: this.getLabel(value) };
  }

  getLabelList() {
    return Object.keys(this.labels()).map((key) => {
      const value = Number(key);
      if (!Number.isNaN(value)) {
        return this.getLabel(key);
      }
      return this.getLabel(key);
    });
  }
}

export default Enum;
