import Client from './HttpClient';

const availableTime = (
  merchantId,
  branchId,
  deliveryMethod,
  addressId,
  time
) => {
  return Client.get(`/eagle-api/online/omsDeliveryTimeSettings`, {
    merchantId,
    branchId,
    time,
    deliveryMethod,
    addressId,
  });
};

export default {
  availableTime,
};
